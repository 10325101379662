import "./host.css";

import { hostEventBroker, HostEvent } from "../../../domain/events/host-events";
import { useState, useEffect } from "react"
import { Host } from "../../components/host/host";


export const HostWidget = ({ right } : { right?: boolean }) => {
    const [ events, setEvents ] = useState([] as Array<HostEvent>);

    useEffect(() => {
        async function getEvents() {
            const events = await hostEventBroker.getEvents();
            setEvents(events);
        }
        getEvents().then(_ => {
            setInterval(getEvents, 10000);
        });
    }, [])

    if (events.length === 0)
        return <>Loading...</>;

    const now = new Date();
    const currentHost = events.find(e => e.endTime.isAfter(now));

    return <div className={"host-widget__widget" + (right ? " host-widget__widget--right" : "")}>
            { currentHost && <Host {...currentHost} /> }
        </div>;
}