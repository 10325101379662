import "./eventItem.css";

import React  from "react";
import { PerformanceEvent } from "../../../domain/events/performance-events";

export interface EventItemProps {
    event: PerformanceEvent;
}

export const EventItem = (props: EventItemProps) => {
    const startTime = props.event.startTime;
    const formattedTime =
        `${startTime.format("h:mm a")} est`;
    const twitchLink = `https://twitch.tv/${props.event.twitch || ""}`;
    const displayLink = props.event.webLink ? props.event.webLink.split("//")[1] : undefined;

    return <div className="event-item__container">
            <p className="event-item__from">
                <span>
                    {formattedTime}
                </span>
            </p>
            <div className="event-item__image-container">
                {props.event['imageLink'] &&
                  <img className="event-item__image" src={props.event['imageLink']} alt={`Logo for ${props.event.name}`}/> }
            </div>
            <div className="event-item__content-container">
                <h3 className="event-item__name">
                    {props.event.name}
                </h3>
                <p className="event-item__pronouns">
                    {props.event.pronouns}
                </p>
                {props.event.twitch &&
                    <h4 className="event-item__link">
                        <i className="fa fa-twitch" />
                        <a href={twitchLink} target="_blank" rel="noreferrer">{props.event.twitch}</a>
                    </h4>
                }
                {props.event.webLink &&
                  <h4 className="event-item__link">
                    <i className="fa fa-globe" />
                    <a href={props.event.webLink} target="_blank" rel="noreferrer">{displayLink}</a>
                  </h4>
                }
                {props.event['blurb'].split("\n").map((p, i) =>
                    <p className="event-item__blurb" key={i}>{p}</p>
                )}
            </div>
        </div>;
}
