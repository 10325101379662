import { Moment } from "moment";
import { Event } from "../../event";

export class PerformanceEvent extends Event {
    public blurb:string;
    public imageLink:string;
    public webLink:string;

    constructor(name:string, pronouns: string, blurb:string, startTime:Moment, endTime:Moment, imageLink:string, twitch:string, webLink:string) {
        super(name, pronouns, twitch, startTime, endTime);
        this.blurb = blurb;
        this.imageLink = imageLink;
        this.webLink = webLink;
    }

}
