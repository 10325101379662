import "./app.css";

import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import { Default } from "../pages/default/default";
import { ScheduleWidget } from "../widgets/schedule/schedule";
import { HostWidget } from "../widgets/host/host";
import {PerformanceWidget} from "../widgets/performance/performance";


export const App = () =>
    <BrowserRouter>
        <Routes>
            <Route index element={<Default />} />
            <Route path="widget/schedule" element={<ScheduleWidget />} />
            <Route path="widget/performance" element={<PerformanceWidget />} />
            <Route path="widget/performance/right" element={<PerformanceWidget right />} />
            <Route path="widget/host" element={<HostWidget />} />
            <Route path="widget/host/right" element={<HostWidget right />} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    </BrowserRouter>
