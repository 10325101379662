import { spreadsheetId, performanceScheduleSheetRange } from "../../../env"
import { fetchGoogleSheetValues, MajorDimension } from "../../services/googleSheets";
import { PerformanceEvent } from "./model/performanceEvent";
import moment from "moment/moment";

const NAME_COL_INDEX = 0;
const PRONOUNS_COL_INDEX = 1;
const BLURB_COL_INDEX = 2;
const START_TIME_COL_INDEX = 3;
const END_TIME_COL_INDEX = 4;
const IMAGE_LINK_COL_INDEX = 5;
const TWITCH_LINK_COL_INDEX = 6;
const WEB_LINK_COL_INDEX = 7;

class PerformanceEventBroker {

    async getEvents() : Promise<Array<PerformanceEvent>> {
        const events:Array<PerformanceEvent> = [];

        const response = await fetchGoogleSheetValues(spreadsheetId, performanceScheduleSheetRange, MajorDimension.ROWS);
        const responseContent = await response.json();
        const rows:Array<Array<string>> = responseContent.values;
        for (let row of rows) {
            if (!row[NAME_COL_INDEX] || row[NAME_COL_INDEX].trim() === "")
                continue;
            const startDate = moment.parseZone(row[START_TIME_COL_INDEX] + " -05:00", `DD/MM/YYYY HH:mm:SS ZZ`);
            const endDate = moment.parseZone(row[END_TIME_COL_INDEX] + " -05:00", `DD/MM/YYYY HH:mm:SS ZZ`);
            const event = new PerformanceEvent(
                row[NAME_COL_INDEX],
                row[PRONOUNS_COL_INDEX],
                row[BLURB_COL_INDEX],
                startDate,
                endDate,
                row[IMAGE_LINK_COL_INDEX],
                row[TWITCH_LINK_COL_INDEX],
                row[WEB_LINK_COL_INDEX]);
            events.push(event);
        }
        return events;
    }

}

const performanceEventBroker = new PerformanceEventBroker();
export { performanceEventBroker };
