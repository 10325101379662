import lilyLogo from "./assets/lily.jpg";
import desertRoseLogo from "./assets/desertrose.png";
import kana from "./assets/kaanaa.png";

export const staff = [
    {
        logo: lilyLogo,
        name: "Lily Velour",
        position: "Producer",
        linkLabel: "twitch.tv/lilyvelour",
        link: "https://twitch.tv/lilyvelour"
    },
    {
        logo: desertRoseLogo,
        name: "Desert Rose Studios",
        position: "Photography",
        linkLabel: "desertrosestudios.carrd.co",
        link: "https://desertrosestudios.carrd.co/"
    },
    {
        logo: kana,
        name: "Kana Ki",
        position: "Web Developer",
    }
]
