import "./performance.css";

import { performanceEventBroker, PerformanceEvent } from "../../../domain/events/performance-events";
import { useState, useEffect } from "react"
import { EventItem } from "../../components/eventItem/eventItem";

export const PerformanceWidget = ({ right } : { right?: boolean })  => {
    const [ events, setEvents ] = useState([] as Array<PerformanceEvent>);

    useEffect(() => {
        async function getEvents() {
            const events = await performanceEventBroker.getEvents();
            setEvents(events);
        }
        getEvents().then(_ => {
            setInterval(getEvents, 10000);
        });
    }, [])

    if (events.length === 0)
        return <>Loading...</>;

    const now = new Date();
    const current = events.find(e => e.endTime.isAfter(now));

    // HACK: Get rid of interview prefix for widget only
    if (current?.name)
        current.name = current.name.replace("Interview: ", "");

    return <div className={"performance-widget__widget" + (right ? " performance-widget__widget--right" : "")}>
        { current &&
        <div className="performance-widget__performer">
            <EventItem event={current} key={current.name + current.startTime.toJSON()} />
        </div>}
    </div>;
}