export const unique =
  <T>(arr: Array<T>, keyFunction: (item: T) => any): Array<T> => {
    const seen = new Set();
    return arr.reduce((result : Array<T>, obj) => {
      const key = keyFunction(obj);
      if (!seen.has(key)) {
        seen.add(key);
        result.push(obj);
      }
      return result;
    }, []);
  };