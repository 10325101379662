import "./default.css";

import { performanceEventBroker, PerformanceEvent } from "../../../domain/events/performance-events";
import React, {useState, useEffect } from "react"
import { EventItem } from "../../components/eventItem/eventItem";
import ReactPlayer from 'react-player'
import logo from "./logo.png";
import {Config, configBroker} from "../../../domain/config";
import {HostEvent, hostEventBroker} from "../../../domain/events/host-events";
import {Host} from "../../components/host/host";
import {unique} from "../../../util/unique";
import {staff} from "../../../data/staff";

export const Default = () => {
    const [ events, setEvents ] = useState([] as Array<PerformanceEvent>);
    const [ hosts, setHosts ] = useState([] as Array<HostEvent>);
    const [ config, setConfig ] = useState(new Config(false));

    useEffect(() => { configBroker.getConfig().then(setConfig) }, [])
    useEffect(() => { performanceEventBroker.getEvents().then(setEvents) }, [])
    useEffect(() => { hostEventBroker.getEvents().then(setHosts) }, [])

    return <div className="default-page__page">
      <div className="default-page__container">
        <h1>
          <img className="default-page__header-logo" src={logo} alt="Burgundy Gives Back"/>
        </h1>
        <h2 className="default-page__tag-line">A WINTER EXTRAVAGANZA BENEFITING TRANS LIFELINE!</h2>

        {(window.location.toString().endsWith("?stream") || new Date() > new Date("2024-12-07T16:30:00.000Z")) &&
          <div className="default-page__twitch-embed">
            <ReactPlayer url='https://www.twitch.tv/theburgundyroom' width="100%" height="100%"/>
          </div>
        }

        <article className="default-page__intro">

          <div className="default-page__blurb">
            <p>
              Join us for a 12-hour holiday celebration as <strong>Burgundy Gives Back</strong> to our community.
              We’re proud to support <strong>Trans Lifeline*</strong> during this event, a trans-led organization
              that connects trans people to the community, support, and resources they need to
              survive and thrive.
            </p>
            <p>
              Be a part of the fun and festivities at our venue in Final Fantasy XIV
              or watch live on <strong>Twitch:&nbsp;
              <a href="https://twitch.tv/theburgundyroom">twitch.tv/theburgundyroom</a>
            </strong>!
            </p>
          </div>

          <div className="default-page__details">
            <p className="default-page__date">Saturday, December 7</p>
            <p className="default-page__time">Starting at 12 PM through 12 AM (Eastern)</p>

            <div className="default-page__location">
              <div className="default-page__location-part">
                <span className="default-page__location-label">Datacenter</span>
                <span className="default-page__location-value">Crystal</span>
              </div>
              <div className="default-page__location-part">
                <span className="default-page__location-label">Server</span>
                <span className="default-page__location-value">Diabolos</span>
              </div>
              <div className="default-page__location-part">
                <span className="default-page__location-label">Address</span>
                <span className="default-page__location-value">Goblet <span>W</span>11 <span>P</span>19</span>
              </div>
            </div>
          </div>
        </article>

        <a className="default-page__button" rel="noreferrer" href="https://burgundygivesback.com/donate"
           target="_blank">Donate to Trans Lifeline!</a>


        {config.showScheduleOnHome &&
          <div className="default-page__events">
            <h2>Schedule</h2>
            {events.map((event, i) =>
              <EventItem event={event} key={event.name + event.startTime.toJSON()}/>
            )}
          </div>
        }


        <div className="default-page__host-grid">
          <div className="default-page__hosts">
            <h2>Hosts</h2>
            {hosts && unique(hosts, h => h.twitch).map((host, i) =>
              <Host name={host.name} pronouns={host.pronouns} twitch={host.twitch}/>
            )}
          </div>
          <div className="default-page__staff">
            <h2>Staff</h2>
            {staff.map((staff, i) =>
              <div className="default-page__staff-member">
                <img src={staff.logo} alt={staff.name}/>
                <div>
                  <h3>{staff.name}</h3>
                  <p>{staff.position}</p>
                  {staff.link &&
                    <h4 className="default-page__staff-link">
                      <i className="fa fa-globe"/>
                      <a href={staff.link} target="_blank" rel="noreferrer">{staff.linkLabel}</a>
                    </h4>
                  }
                </div>
              </div>
            )}
          </div>
        </div>


        <div className="default-page__disclaimer">
          * While all donations will go to the charity, Burgundy Gives Back is not officially endorsed by Trans
          Lifeline.
        </div>
      </div>
    </div>;
}